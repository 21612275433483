.h-wrapper {
  /* background-color: var(--black); */
  padding: 0 2.2rem;
}

.h-container {
  justify-content: space-between;
  padding: 1rem 0;
  color: var(--secondary);
  cursor: pointer;
}

.h-menu {
  gap: 2rem;
}

.menu-icon {
  display: none;
}

.menu-icon svg {
  color: white;
}

@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .h-menu {
    color: var(--black);
    position: absolute;
    top: 3rem;
    right: 4rem;
    background: white;
    flex-direction: column;
    font-weight: 500;
    gap: 2rem;
    padding: 3rem;
    z-index: 10;
    border-radius: 25px;
    align-items: flex-start;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transition: all 300ms ease-in;
  }
}
