.f-container {
  justify-content: space-between;
}

.f-left {
  gap: 1rem;
}

.f-menu {
  margin-top: 1.5rem;
  gap: 1.5rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .f-container {
    justify-content: center;
  }

  .f-container > div {
    align-items: center;
  }
}
